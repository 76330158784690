/**
 * This plugin is used to provide a reactive variable to check if the app is mounted or not.
 */

import { reactive } from 'vue';
export default defineNuxtPlugin((nuxtApp) => {
  // Provide a reactive variable to check if the app is mounted or not.
  // In case of Page reload or fresh launch of the page the rendering happens via SSR
  // In this scenario, the screen reader reads the page title correctly.
  // In case of intra-app navigation , when page rendering happens via CSR, the screen reader does not read the page title.
  // To ensure that screen readers are notified when the page title changes, we used a hidden element in the DOM with aria-live attributes.
  // But the presence of this hidden element causes the screen reader to announce the page title changes twice during SSR navigation.
  // Thus we are using this reactive variable to check if the app is mounted or not.
  // The hidden element is used to announce the page title changes only when the app is mounted.
  // Refer page-title.ts for more detailed explanation.
  // https://btsservice.atlassian.net/browse/NEMO-19807
  nuxtApp.provide('isAppMounted', reactive({ value: false }));
});
