import revive_payload_client_xAVIhWFsoH from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uCA0IcvyjV from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cIGszAsECW from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mT24z1HRVL from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y3pCeWbMoG from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_bGEi9l5Zhs from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_IAb3dzxmX5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.11.30_eslint@9.16.0_jiti@2.4.1__ioredis@5.4._vzadp2azwfeviymmn7ouc3f4wy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_MsPNfHGj0b from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_CjtJ0bKVt6 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_YY7eh0KBSU from "/app/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_OHU0vggoM9 from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_cFLzY6iUPf from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_qukqxx4hlqoezopjjby76cgas4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import app_lifecycle_client_TeF8g7qPkg from "/app/apps/onboarding/plugins/app-lifecycle.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_sZGbScZEgF from "/app/apps/onboarding/plugins/page-view-event.client.ts";
export default [
  revive_payload_client_xAVIhWFsoH,
  unhead_uCA0IcvyjV,
  router_cIGszAsECW,
  payload_client_mT24z1HRVL,
  navigation_repaint_client_Y3pCeWbMoG,
  chunk_reload_client_bGEi9l5Zhs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IAb3dzxmX5,
  switch_locale_path_ssr_MsPNfHGj0b,
  i18n_CjtJ0bKVt6,
  plugin_client_YY7eh0KBSU,
  plugin_OHU0vggoM9,
  plugin_cFLzY6iUPf,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  app_lifecycle_client_TeF8g7qPkg,
  image_thumbnail_update_G2rT6oNNpa,
  page_view_event_client_sZGbScZEgF
]